export const send = (form) => {

  const { trackingEvent, trackingCategory, trackingLabel = false, trackingValue = false } = form.dataset;

  if (typeof gtag === 'function') {
    gtag(
      'event',
      trackingEvent,
      {
        'event_category': trackingCategory,
        ...(trackingLabel && { 'event_label': trackingLabel }),
        ...(trackingValue && { 'value': trackingValue })
      }
    );
  }

  if (typeof (fbq) === 'function') {
    fbq('track', 'Lead');
  }

};
