import Errors from "./errors";

class Form {

  constructor() {
    this.fields = {};
    this.errors = new Errors();
  }

  reset() {
    for (let field in this.fields) {
      this.fields[field] = '';
    }
    this.errors.clear();
  }

  data() {
    return Object.assign({}, this.fields);
  }

}

export default Form;
