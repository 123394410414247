import * as tracking from './tracking';

window.Vue = require('vue');

import http from './http';
import Form from './form';

const app = new Vue({
  el: '#laravel-subscribe',
  data: {
    form: new Form(),
    message: '',
    error: '',
    url: '',
    data: {}
  },
  methods: {
    onSubmit() {
      this.message = '';
      this.error = '';
      this.data = Object.assign(this.form.data(), {'locale': this.$refs.subscribeForm.dataset.locale});
      this.url = this.$refs.subscribeForm.dataset.url;

      http
        .post(this.url, this.data)
        .then(response => this.onSuccess(response))
        .catch(error => this.onFail(error));
    },
    onSuccess(response) {
      tracking.send(this.$refs.subscribeForm);

      this.message = response.data.message;
      this.form.reset();
    },
    onFail(error) {

      if (error.response.status === 422) {
        this.form.errors.set(error.response.data.errors);
        return;
      }
      if (error.response.status === 404) {
        this.error = 'Unable to reach API';
        return;
      }
      if (error.response.status === 500) {
        this.error = error.response.data.message;
      }
    }
  }
});
